.auction-details {
  .notification-input-field-container {
    flex-direction: column;

    .input-wrapper {
      width: 100%;
    }
  }

  .show-page div:first-child {
    position: relative;
  }

  .title {
    position: absolute;
    top: -50px;
    background: transparent;
    z-index: 100;
    padding-left: 15px;
  }

  .show-page {
    > div {
      box-shadow: none;
      background-color: transparent;
    }
  }

  .liveLotButton {
    position: relative;

    :hover {
      cursor: pointer;
    }

    height: 100px;
    width: 150px;
    margin-left: 15px;
    color: #3f51b5;
    background-color: transparent;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    outline: none;
  }

  .liveLotButton1 {
    position: relative;

    :hover {
      cursor: pointer;
    }

    height: 100px;
    width: 150px;
    margin-left: 15px;
    color: white;
    background-color: #3f51b5;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    outline: none;
  }

  .current-bidder-admin {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: greenyellow;
    border-radius: 5px;
    height: 30px;
    padding-top: 15px;
  }

  .current-bid {
    font-size: 30px;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .span-color {
    background-color: lightgrey;
  }

  .current-bidder-user {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: red;
    border-radius: 5px;
    height: 30px;
    padding-top: 15px;
  }

  .no-bid {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: transparent;
    border-radius: 5px;
    height: 30px;
    padding-top: 15px;
  }

  .current-bidder-div {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: greenyellow;
    border-radius: 5px;
    height: 30px;
    padding-top: 15px;
  }

  .bid-div1 {
    -webkit-appearance: none;
    text-align: center;
    padding-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    height: 50px;
    width: 50%;
    border-radius: 5px;
    border: 1px solid #d4e3ef;
    background: transparent;
    outline: none;
    float: left;
    margin-bottom: 10px;
  }

  .bid-div2 {
    text-align: center;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    color: #3f51b5;
    background-color: transparent;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    height: 50px;
    width: 24%;
    margin-bottom: 10px;
  }

  .bid-div3 {
    text-align: center;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
    color: #3f51b5;
    background-color: transparent;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    padding-top: 5px;
    height: 50px;
    width: 25%;
    margin-bottom: 10px;
  }

  .set-bid {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }

  .row-buttons {
    align-content: center;
    display: flex;
    margin-top: 10px;

    justify-content: center;
    width: 100%;
  }

  .bid-btns {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      span {
        display: inline;
      }
    }
  }

  .bid-negative {
    margin-left: 15px;
    width: 50px;
    height: 80px;

    :hover {
      cursor: pointer;
    }

    p {
      margin: 1px;
    }

    color: #3f51b5;
    background-color: transparent;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    outline: none;
    // display: flex;
    /*justify-content: center;
    align-items: center;
    flex-direction: column;*/
    position: relative;
  }

  .bid-positive {
    margin-left: 15px;
    width: 100px;
    height: 80px;

    :hover {
      cursor: pointer;
    }

    p {
      margin: 1px;
    }

    color: #3f51b5;
    background-color: transparent;
    border-color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
    border-radius: 5px;
    outline: none;
    // display: flex;
    /*justify-content: center;
    align-items: center;
    flex-direction: column;*/
    position: relative;
  }

  .table-margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .lotInfoSpan {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }

  .autoBidSpan {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
  }

  .price {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1050px) {
    .row-buttons {
      align-content: flex-start;
      display: flex;
      margin-left: 220px;
      justify-content: left;
      width: 100%;
    }
    .bid-btns {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 200px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .table-margin {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 5px;
      width: 83%;
    }
    .set-bid {
      display: flex;
      flex-direction: row;
      width: 86%;
    }
    .current-bidder-user {
      text-align: center;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      background-color: red;
      border-radius: 5px;
      height: 30px;
      padding-top: 15px;
      width: 86%;
    }
    .lotInfoSpan {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      width: 85%;
    }
    .autoBidSpan {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
      width: 85%;
    }
  }

  @media (max-width: 800px) {
    .lotInfoSpan {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      width: 84%;
    }

    .autoBidSpan {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
      width: 84%;
    }

    .row-buttons {
      align-content: flex-start;
      display: flex;
      margin-left: 200px;
      justify-content: left;
      width: 100%;
    }
    .bid-btns {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 150px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .set-bid {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 85%;
    }

    .current-bidder-user {
      text-align: center;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      background-color: red;
      border-radius: 5px;
      height: 30px;
      padding-top: 15px;
      width: 84%;
    }

    .current-bidder-div {
      text-align: center;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      background-color: greenyellow;
      border-radius: 5px;
      height: 30px;
      padding-top: 15px;
      width: 84%;
    }

    .table-margin {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 5px;
      width: 83%;
    }
  }

  .auction-info-wrapper {
    background-color: #EFEFEF;
    box-shadow: none;
    border: none;
    width: 100%;
    padding-left: 0;

    .info-table-div-wrapper {
      margin-top: 50px;
    }

    .auction-info .auction-info1 {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      background-color: #F9F9F9;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-left: 12px !important;

      .info-wrapper {
        width: calc(77vw - 300px);
        min-width: 700px;
        display: inline-block;
        padding: 0;

        .description-layout {

          .MuiFormControl-root {
            padding-left: 35px;

            label {
              font-size: 20px;
              color: #B9B9B9;
            }
          }
        }

        .admin-layout {

          .MuiFormControl-root {
            padding-left: 35px;

            label {
              font-size: 20px;
              color: #f70808;
            }
          }
        }

        .info-table {
          display: inline-block;
          width: 50%;
          margin-bottom: 20px;
        }

        .auction-useful-links-wrapper {
          width: 100%;
          padding: 20px 0;

          .table-wrapper {
            width: 50%;

            .label {
              min-width: 135px;
            }
          }
        }
      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        width: 265px;

        &:first-of-type {
          padding-right: 10px;
        }

        div {
          display: flex;
          padding: 0;
          width: 100%;

          button {
            font-size: 12px;
            margin: 0 5px 10px;
            width: 100%;
            outline: none;
          }
        }

        .input-wrapper input {
          width: 100%;
          height: 30px;
          border: 1px solid #d4e3ef;
          background: transparent;
          outline: none;
          margin: 0 5px 12px;
        }
      }
    }

    .collapse-wrapper {
      margin-top: 0;
    }

    #collapse-content-lots, #collapse-content-liveLots, #collapse-content-vendors, #collapse-content-buyers, #collapse-content-bids {
      padding-right: 5px;
      padding-left: 5px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
      background: #f9f9f9;
      margin-top: 20px;
      border-radius: 4px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
      }
    }
  }

  .collapse-wrapper {
    .header {
      box-sizing: border-box;
      padding: 20px;
    }
  }

  .search-text {
    z-index: 1;
    margin-top: auto;
    position: absolute !important;
    float: right;
    right: 150px;
    top: -70px;
  }

  .export-button {
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    right: 0;
    top: 17px;
    z-index: 100;
  }
}
