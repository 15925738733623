.additional-date-info-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 700px !important;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.54);
  padding: 20px;
  margin-bottom: 15px;

  .main-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
  }

  .icon-button-file-remove, .icon-button-file-plus {
    margin-left: 6px;
    margin-top: 6px;
  }

  .title {
    width: 256px;
  }

  .date-description {
    width: 256px;
    margin-left: 50px;
    margin-top: 5px;
  }

  .error-message {
    position: absolute;
    bottom: -10px;
    left: 170px;
    width: 100%;
    font-size: 13px;
    color: red;
  }
}