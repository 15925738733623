.date-info-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 700px !important;
  flex-direction: column;

  .spacing-label {
    margin-top: 8px;
  }
  .floating-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    display: block;
    width: 100%;
  }

  .date-field {
    width: 256px;
  }

  .error-message {
    width: 100%;
    font-size: 13px;
    color: red;
  }
}