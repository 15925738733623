.useful-link-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px !important;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.54);
  padding: 20px;
  margin-bottom: 15px;

  .useful-main-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
  }
  .useful-link-text-description {
    margin-left: 50px;
  }
  .icon-button-file-remove,.icon-button-file-plus {
    margin-left: 6px;
    margin-top: 6px;
  }

  .useful-link-upload-pdf{
    margin-top: 20px;
    min-width: 0 !important;
    max-width: none !important;
    width: 100%;
    h1 {
      font-size: 12px;
      color: #999;
      font-weight: 200;
    }

    .drop-zone {
      color: #999;
      cursor: pointer;
      padding: 20px 0;
      background: #efefef;
      text-align: center;
      width: 100%;
      max-width: none;

      &.active {
        background: #bdbcbc;
      }
    }

    .item {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 20px;
      max-width: none;
      min-width: 0;

      .leftSide {
        width: 50%;
        display: flex;
        min-width: 0;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .delete-btn {
          cursor: pointer;
          color: rgba(0, 0, 0, 0.54);
          margin: 15px;
        }
      }

      .description {
        width: 50%;
        min-width: 0;
        margin-left: 50px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .document-image {
      display: block;
      width: 50px !important;
      height: 50px;
    }
  }
}
