.message-select-item {
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto !important;
  white-space: normal !important;
}

#message-select-menu {
  .message-select-paper {
    width: 20%;
    height: auto;
  }

  ul {
    padding: 10px;
  }
}

#active-lot-window-wrapper {
  display: flex;
  justify-content: center;

  .auction-details {
    margin-top: 40px;
    max-width: 1100px;
  }

  .auction-details .show-page {
    .set-bid {
      &.message {
        div:first-child {
          background: none;
        }
      }
    }
  }

  .bid-div1 {
    width: 50%;
    padding-top: 0;
    margin-right: 5px;
    margin-left: 5px;
  }

  .bid-div2 {
    width: 25%;
    margin-right: 5px;
    margin-left: 5px;
  }

  .bid-div3 {
    width: 25%;
    margin-right: 5px;
    margin-left: 5px;
  }

  .auction-info-wrapper {
    thead {
      background-color: rgb(211, 211, 211) !important;

      th {
        padding: 0 12px;
        border-right: 1px solid white;
        border-bottom: none;

        &:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      background-color: white;

      td {
        padding: 0 12px;
        border-right: 1px solid rgb(224, 224, 224);

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .bid-btns {
    .button-bid-spinner {
      position: absolute;
      right: 100px;
    }
  }

  .set-bid {
    &.message {
      margin-top: 10px;
    }

    .form-control-message-select {
      margin-right: 5px !important;
      margin-left: 5px;
      width: 50%;
      margin-bottom: 10px;

      label {
        top: -5px;
        left: calc(50% - 50px);
        font-size: 14px;

        &[data-shrink="true"] {
          transform: translate(0, -8.5px) scale(0.75);
        }
      }

      .message-select {
        border: 1px solid #d4e3ef;
        border-radius: 5px;
        height: 100%;
        border-bottom-color: #d4e3ef !important;
        margin-top: 0 !important;
        max-width: 900px;
        overflow: hidden;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }

        .message-select-input {
          height: 34px;
          padding-bottom: 0;
          background: initial;
          padding-top: 15px;
          padding-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .message-select-item {
    text-overflow: ellipsis;
    overflow: hidden;
    height: auto !important;
    white-space: normal !important;
  }

  #message-select-menu {
    .message-select-paper {
      width: 20%;
      height: auto;
    }
  }

  .message-notification {
    text-align: center;
    padding: 14px;
    background-color: green;
    border-radius: 5px;
    margin: 15px 0;

    &.green {
      background-color: green;
    }

    &.red {
      background-color: red;
    }
  }

  .start-notification {
    &.active {
      color: #fff;
      background-color: #3f51b5;
    }
  }
  .close-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.7);
    z-index: 500;
    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;  
    }
    .text-container {
      h3,
      a {
        display: block;
        text-align: center;
        margin: 5px 0;
        text-transform: uppercase;
        color: #efefef;
      }
      h3 {
        font: bold 23px "Lato";
      }
      a {
        font: normal 16px "Lato";
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
