.auction-tag-container {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  max-width: 650px !important;

  .tag-main-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    width: 100%;
    margin-top: 10px;
  }

  .margin {
    margin-top: 2px;
    display: flex;
    height: 45px;

    span {
      margin-left: 15px;
      padding-top: 25px;
      cursor: pointer;
    }

    .input {
      padding-top: 15px;
    }
    .MuiSelect-root {
      height: 45px;
      padding-bottom: 5px;
      .MuiSelect-select {
        padding-right: 0;
        height: 45px;
        width: calc(100% - 25px);
        min-width: 0 !important;
        max-width: none !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  div {
    width: 256px;
    max-width: 256px !important;
    min-width: 256px !important;
  }

  .plus-icon-wrapper {
    padding-top: 10px;
    margin-left: 5px;
  }

  .new-tag-input {
    margin-top: 0;
    margin-left: 50px;
  }
}