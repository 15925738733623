#kpi-report-table {
  .select {
    margin-top: 15px;
    margin-right: 50px;
    height: 35px;
    color: #777;
  }

  .clearButton {
    margin-right: 50px;
  }

  .rt-table {
    .rt-tr {
      .rt-th:hover {
        cursor: pointer;
      }

      .rt-td {
        padding: 0;
        border-left: 1px solid rgba(0,0,0,0.08);

        p {
          text-overflow: ellipsis;
          padding: 0 5px;
          overflow: hidden
        }
      }
    }

    .subHeader {
      width: calc(100% + 10px);
      height: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }

    .quarterCell, .thirdCell {
      width: 25%;
      float: left;
      height: 100%;

      .subHeader {
        padding-top: 5px;
        cursor: pointer;
      }

      &.left {
        .subHeader {
          margin-left: -10px;
          padding-left: 10px;
          border-right: 1px solid rgba(0, 0, 0, 0.08);
          cursor: pointer;
        }

        p {
          height: 100%;
          margin: 0;
          padding: 16px 5px;
          border-right: 1px solid rgba(0, 0, 0, 0.08);
        }
      }
    }

    .thirdCell {
      width: 33.333%;
    }

    .halfСell {
      width: 50%;
      float: left;

      .subHeader {
        padding-top: 5px;
        cursor: pointer;
      }

      &.left {
        .subHeader {
          margin-left: -10px;
          padding-left: 10px;
          border-right: 1px solid rgba(0, 0, 0, 0.08);
          cursor: pointer;
        }

        p {
          margin: 0;
          padding: 16px 5px;
          border-right: 1px solid rgba(0, 0, 0, 0.08);
        }
      }

      &.right {
        .subHeader {
          margin-left: 0;
          cursor: pointer;
        }
      }
    }
  }

  .total-container {
    display: flex;

    .total-content {
      padding: 0 40px;
    }
  }
  .download-csv {
    margin-right: 50px;
    margin-top: 12px;
  }
  .range-button {
    min-height: 0;
    padding: 11px;
    height: 40px;
    margin-right: 30px;
    margin-top: 13px;
  }
}
