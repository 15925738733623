.admin-notes {
    margin-top: 30px;
    .admin-notes-title {
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
    }
    .admin-note-add {
        display: flex;
    }
    .admin-notes-comment {
        display: flex;
        width: 1200px !important;
        margin-bottom: 20px;
        div {
            width: 800px;
        }
        .admin-note-delete {
            color: #f44336;
            display: flex;
            align-items: flex-end;
            margin-left: 20px;
            height: 40px;
            padding-right: 18px;
            margin-top: 10px;
            &:hover {
                background-color: #f4433645;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
}
