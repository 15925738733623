.auction-tabs-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px !important;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.54);
  padding: 20px;
  margin-bottom: 15px;

  .auction-tabs-text-description {
    margin-left: 50px;
  }
  .icon-button-file-remove,.icon-button-file-plus {
    margin-left: 6px;
    margin-top: 6px;
  }
}
