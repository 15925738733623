.DocumentsInputField {
  h1 {
    font-size: 12px;
    color: #999;
    font-weight: 200;
  }

  .drop-zone {
    color: #999;
    cursor: pointer;
    padding: 20px 0;
    background: #efefef;
    text-align: center;

    &.active {
      background: #bdbcbc;
    }
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;

    .leftSide {
      width: 50%;
      display: flex;

      .delete-btn {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        margin: 15px;
      }
    }

    .description {
      width: 50%;
    }
  }
  .document-image {
    width: 50px !important;
    height: 50px;
  }
}
